export default function (element, obj) {
    let el = element.cloneNode(true)

    el.dataset.rating = obj.rating
    el.id = el.id.split( '-' )[0] + '-' + obj.ID
    el.querySelector('.game-title').innerText = obj.title
    el.querySelector('.game-title-animation').innerText = obj.title
    el.querySelector('.thumb-link').href = obj.permalink
    el.querySelector('.provider').innerText = obj.provider
    // el.querySelector('.rating').innerText = obj.rating

    // Add the rating stars element
    let ratingElement = el.querySelector('.rating');
    let ratingValue = parseFloat(obj.rating);
    ratingElement.innerHTML = '';

    let fullStars = Math.floor(ratingValue);
    let hasHalfStar = ratingValue - fullStars >= 0.5;

    for (let i = 1; i <= 5; i++) {
        let starElement = document.createElement('span');
        starElement.className = 'star';
        if (i <= fullStars) {
        starElement.classList.add('active');
        } else if (hasHalfStar && i === fullStars + 1) {
        starElement.classList.add('active', 'half');
        }
        ratingElement.appendChild(starElement);
    }

    let img = el.querySelector('img')
    img.src = obj.thumbnail_url
    img.title = obj.title
    img.alt = obj.title

    return el;
}