import templateProcessing from './components/template_processing'

const game_search = {
    container: null,
    gamePerPage: -1,
    fields: {},
    state: {
        input: '',
        provider: '',
        category: ''
    },
    attributes: {},
    games: [],
    selectsOptions: {},

    gameSearchInput: function (){
        const input = this.container.querySelector('.game-search-input')
        this.fields.input = input
        let timeout = undefined;

        const _this = this;
        input.addEventListener('keyup', function(e) {
            let input = e.target.value.trim();

            if(input.length == 0 || input.length > 1 ){
                _this.loading()
                let state = {
                    input
                }

                if(input.length > 1){
                    state.provider = state.category =  ''
                }

                _this.setState(state)

                if(typeof timeout === "number"){
                    clearTimeout(timeout)
                }

                timeout = setTimeout(function(){
                    _this.sendRequest()
                }, 400)
            }
        });
    },

    providerSelect: function(){
        const provider = this.container.querySelector('.game-provider')
        this.fields.provider = provider

        const _this = this;
        provider.addEventListener('change', function(e) {
            _this.loading()
            _this.setState({
                provider: e.target.value.trim(),
                input: ''
            })

           if( e.target.selectedOptions[0].classList.contains('disabled') ){
               _this.setState({
                   category: ''
               })
           }

            _this.sendRequest()
        });
    },

    categorySelect: function(){
        const category = this.container.querySelector('.game-category')
        this.fields.category = category

        const _this = this;
        category.addEventListener('change', function(e) {
            _this.loading()
            _this.setState({
                category: e.target.value.trim(),
                input: ''
            })
            _this.sendRequest()
        });
    },

    getSearchAttributes: function(){
        const attrs = this.container.querySelector('.game-search-attributes')
        this.attributes = JSON.parse(attrs.value);
    },

    loading: function(remove = false){
        if(remove){
          return  this.gameGrid.style.opacity = ''
        }

        this.gameGrid.style.opacity = 0.3;
    },
    
    addGames: function(){
        this.setSelectsOptions()
        
        this.gameGrid.innerHTML = ''
        this.showGamesNotFound(false)

        this.games = this.setLoadMore(this.games)

        if(this.games.length){
            this.games.forEach(obj => {
                this.gameGrid.appendChild(templateProcessing(this.oneGameTemplate, obj))
            });
        }else{
            this.showGamesNotFound()
        }
    },

    showGamesNotFound: function(show = true){
        if(show){
            this.gamesNotFound.style.display = ''
        }else{
            this.gamesNotFound.style.display = 'none'
        }
    },
    
    setLoadMore: function(games){
        if(!this.loadMore){
            return games
        }

        const loadMoreBTN = this.loadMore.querySelector('.button')

        if(this.gamePerPage === -1) {
            loadMoreBTN.style.display = 'none'
            return games
        }

        let gamesSlice = games.slice(0, this.gamePerPage)
        let gamesArray = games.slice(this.gamePerPage)

        if(!gamesArray.length){
            loadMoreBTN.style.display = 'none'
        }else{
            loadMoreBTN.style.display = ''

            this.loadMore.querySelector('#games-list').innerText = JSON.stringify(gamesArray)
        }

        return gamesSlice
    },
    
    setSelectsOptions: function(){
        if(this.state.provider && this.state.category){
            return
        }

        Object.keys(this.selectsOptions).forEach(key =>{
            if(this.selectsOptions[key].length > 0 || this.state.input){
                if(key in this.fields){
                    Array.from(this.fields[key].options).forEach(option =>{
                        if(option.innerText.trim() == '') return

                        if(this.selectsOptions[key].includes(option.innerText)){
                            option.classList.remove("disabled")
                        }else{
                            option.classList.add("disabled")
                        }
                    })
                }//if
            }else{
                Array.from(this.fields[key].options).forEach(option =>{
                    option.classList.add("disabled")
                })
            }
            
            if(this.state.provider.length === 0 && this.state.category.length === 0){
                Array.from(this.fields[key].options).forEach(option =>{
                    option.classList.remove("disabled")
                })
            }
        })
    },
    
    sendRequest: function(){
        const ajax = window.ajax_var;

        fetch(ajax.url+'?action=search_games',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({inputs: this.state, attributes: this.attributes, NONCE: ajax.nonce}),
        }).then(response => response.json())
          .then(data => {
              if(data.response === 'OK'){
                  this.games = data.games
                  this.selectsOptions = data.params
                  this.loading(true)
                  this.addGames()
              }
        });
    },

    setState: function(newState){
        const $this = this
        Object.keys(newState).forEach(key =>{
            if(key in $this.state){
                $this.state[key] = newState[key]
            }
        })
        this.fieldChanger();
    },

    fieldChanger: function(){
        const $this = this
        Object.keys(this.state).forEach(key =>{
            if(key in $this.fields){
                $this.fields[key].value = this.state[key]
            }
        })
    },

    init: function (gameContainer){
        this.container = gameContainer

        this.gameGrid = this.container.querySelector('#rm-game-grid')
        this.gameSearch = this.container.querySelector('.game-search')
        this.oneGameTemplate = this.gameGrid.querySelector('li.item')
        this.loadMore = this.container.querySelector('.load-more')
        this.gamesNotFound = this.container.querySelector('.game_not_found')

        if(this.gameGrid && this.gameSearch){
            this.gamePerPage = this.gameGrid.querySelectorAll('li.item').length

            this.gameSearchInput()
            this.providerSelect()
            this.categorySelect()
            this.getSearchAttributes()
        }
    }
}

const gameContainer = document.querySelectorAll('.game-grid-wrap')

if(gameContainer.length){
    gameContainer.forEach(g=>{
        game_search.init(g)
    })
}
