// vagas grid load more button
import templateProcessing from "./components/template_processing";

const loadMore = {
    gameGrid: document.querySelector('#rm-game-grid'),
    button: document.querySelector('.load-more .button'),
    oneGameTemplate: document.querySelector('#rm-game-grid li.item'),
    loadGames: 20,

    getLoadGames: function(){
        this.loadGames = this.button.dataset.loadGames
    },
    buttonClick: function(){
        this.button.addEventListener("click", ()=>{
            const gameList = document.getElementById('games-list')
            let gamesArray = gameList ? JSON.parse(gameList.textContent) : null
            
            let galesSlice = gamesArray.slice(0, this.loadGames)
            gamesArray = gamesArray.slice(this.loadGames)

            gameList.innerText = JSON.stringify(gamesArray)

            if(!gamesArray.length){
                this.button.style.display = 'none'
            }

            galesSlice.forEach(obj => {
                this.gameGrid.appendChild(templateProcessing(this.oneGameTemplate, obj))
            });
        });
    },
    init: function (){
        if(this.gameGrid && this.button){
            this.getLoadGames();
            this.buttonClick();
        }
    }
};
loadMore.init();